import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { CompanyStateService } from '@dougs/company/shared';
import { SalesInvoicePaymentStatus, SalesInvoiceType } from '@dougs/sales-invoice/dto';

@Injectable()
export class SalesInvoiceFilterComponentService {
  constructor(private readonly companyStateService: CompanyStateService) {}

  resetFiltersOnCompanyChanged$: Observable<void> = this.companyStateService.activeCompanyIdChanged$.pipe(
    map(() => {
      this.setInvoiceType(SalesInvoiceType.FINALIZED);
      this.setActiveFilter(null, true);
    }),
  );

  salesInvoiceType: BehaviorSubject<SalesInvoiceType> = new BehaviorSubject<SalesInvoiceType>(
    SalesInvoiceType.FINALIZED,
  );
  salesInvoiceType$: Observable<SalesInvoiceType> = this.salesInvoiceType.asObservable();

  salesInvoicesFilters: BehaviorSubject<SalesInvoicePaymentStatus | null> =
    new BehaviorSubject<SalesInvoicePaymentStatus | null>(null);
  salesInvoiceFilter$: Observable<SalesInvoicePaymentStatus | null> = this.salesInvoicesFilters.asObservable();

  setInvoiceType(type: SalesInvoiceType): void {
    if (type !== this.salesInvoiceType.value) {
      this.salesInvoiceType.next(type);
      if (type === SalesInvoiceType.FINALIZED) {
        this.setActiveFilter(null);
      }
    }
  }

  setActiveFilter(filter: SalesInvoicePaymentStatus | null, force = false): void {
    if (force || filter !== this.salesInvoicesFilters.value) {
      this.salesInvoicesFilters.next(filter);
    }
  }
}
